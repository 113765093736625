import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/glossar"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-sidebar">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/teaser_03.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 15px 20px 15px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-family": "Georgia",
                          "font-style": "italic",
                          "font-weight": "normal",
                          "font-size": "20px",
                          "line-height": "26px",
                          margin: "20px 0 10px 0"
                        }}
                      >
                        Sie erreichen uns kostenlos
                        <br />
                        00800 260 260 00
                      </h4>
                      <p>
                        <span
                          style={{
                            "font-size": "13px",
                            color: "#767575"
                          }}
                        >
                          mo-fr: 09:00 - 16:00 Uhr
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                  <h1><div class="field field-name-field-title-text field-type-text field-label-hidden">
                  <div class="field-items"><div class="field-item even">Glossar</div></div></div></h1>
                    <div className="view view-glossar view-id-glossar view-display-id-page view-dom-id-0d96fad90b7493d68c01a11dd3db30f8">
                      <div className="view-content">
                        <div className="views-row views-row-1 views-row-odd views-row-first">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Akuter Durchfall
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Von akutem Durchfall spricht man bei plötzlichen Durchfallattacken, bei denen die Stuhlentleerung mehr als drei Mal pro Tag stattfindet, die jedoch nur kurzfristig anhalten.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-2 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Abführmittel
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Arzneimittel, die den Stuhl aufweichen, den
                                    Darminhalt mit Flüssigkeit anreichern und
                                    die Darmbewegungen verstärken, um
                                    Verstopfung zu beseitigen; können bei
                                    übermäßigem Gebrauch zu Durchfall führen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-3 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Allergie</div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Reaktion des Immunsystems auf
                                    harmlose Fremdsubstanzen. Substanzen, die Allergien
                                    auslösen, werden Allergene genannt, z. B.
                                    Pollen, Hausstaubmilben und bestimmte
                                    Nahrungsmittel.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-4 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Antibiotika</div>
                                <div className="glossar_body">
                                  <p>
                                    Arzneimittel zur Behandlung von Infektionen,
                                    die durch Bakterien und andere
                                    Mikroorganismen verursacht werden.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-5 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Aufgeblähter Bauch
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Der Bauch fühlt sich häufig voll und hart
                                    an, wenn Darmgase ihn aufblähen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-6 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Bakterien</div>
                                <div className="glossar_body">
                                  <p>
                                    Einzellige Mikroorganismen, die häufig mit
                                    Infektionen in Verbindung gebracht werden;
                                    einige von ihnen sind jedoch harmlos
                                    und/oder nützlich für den Menschen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-7 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Bauch-</div>
                                <div className="glossar_body">
                                  <p>
                                    Auf den Bauch bezogen. Der Bauch ist der
                                    Teil des Körpers, der zwischen der Brust und
                                    dem Becken liegt. Zum Bauch gehören Organe
                                    wie der Magen, der Dünndarm, der Dickdarm,
                                    der Enddarm und die Blase.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-8 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Blähungen</div>
                                <div className="glossar_body">
                                  <p>
                                    Übermäßige Gasentwicklung im Darm. Bis zu maximal 24 Gasabgänge am Tag gelten noch als 'normal'.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-9 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Chronischer Durchfall
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Als chronischer Durchfall werden Symptome bezeichnet, die länger als vier Wochen andauern oder wiederholt auftreten.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-10 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Colitis ulcerosa
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Chronische Entzündung des Dickdarms.
                                    Typische Symptome sind Blut im Stuhl,
                                    Durchfälle und Bauchschmerzen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-11 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Darm</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Teil des Verdauungssystems, der im
                                    Bauchraum liegt und aus dem Zwölffingerdarm,
                                    den Dünndarm und dem Dickdarm besteht.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-12 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Dehydrierung
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Wenn der Körper zu viel Wasser verliert.
                                    Erbrechen oder Durchfall über längere Zeit
                                    kann zu Dehydrierung führen, ebenso wie
                                    Hitzeeinwirkung, intensive sportliche
                                    Betätigung über längere Zeit und manche
                                    Medikamente.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-13 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Durchfall</div>
                                <div className="glossar_body">
                                  <p>
                                    Ungewöhnlich lockere oder flüssige Stühle in
                                    Schüben, die mehr als dreimal am Tag
                                    auftreten und/oder Stühle mit einem Volumen
                                    von mehr als 200 g an einem Tag.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-14 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Gastroenteritis
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Entzündung des Magens und des Darms,
                                    die Übelkeit, Erbrechen und/oder Durchfall
                                    hervorrufen kann. Viren,
                                    Lebensmittelvergiftung und Stress können
                                    Gastroenteritis hervorrufen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-15 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Immunsystem</div>
                                <div className="glossar_body">
                                  <p>
                                    Das natürliche Abwehrsystem unseres Körpers.
                                    Es erkennt Fremdsubstanzen und schützt uns
                                    gegen Infektionen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-16 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Krampflösendes Mittel / Spasmolytikum
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Medikament, das Muskelkrämpfe minimiert
                                    oder verhindert.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-17 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Loperamid</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Wirkstoff, der zur Behandlung von
                                    Durchfall verwendet wird, der die
                                    Muskelkontraktionen im Darm verlangsamt und
                                    die Aufnahme von Flüssigkeit und
                                    Elektrolyten in den Körper verbessert.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-18 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Menstruation
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Die regelmäßige Blutung aus der Gebärmutter,
                                    die in Abständen von ca. 4 Wochen auftritt
                                    und den Menstruationszyklus bestimmt.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-19 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Morbus Crohn
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Chronische Entzündung des Darmtraktes, die meist Dick- und Dünndarm betrifft. Typische Symptome
                                    sind Bauchschmerzen und Durchfall.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-20 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Nährstoffe</div>
                                <div className="glossar_body">
                                  <p>
                                    Substanzen in Nahrungsmitteln, die der
                                    Körper zur Erzeugung von Energie, für
                                    Wachstum und Regeneration braucht wie
                                    Vitamine, Mineralien, Kohlehydrate, Fette
                                    und Proteine.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-21 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Norovirus</div>
                                <div className="glossar_body">
                                  <p>
                                    Es gibt viele Arten von Noroviren; gemeinsam
                                    gehören sie zu einer Gruppe von Viren, die
                                    den häufigsten Grund von Gastroenteritis
                                    darstellen. Obwohl sich zu jeder Jahreszeit
                                    Menschen damit infizieren können, wird die
                                    Infektion im Englischen auch als 'winter
                                    vomiting disease' (winterliche
                                    Brech-Krankheit) bezeichnet, da sie am
                                    häufigsten im Winter auftritt.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-22 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Peristaltik</div>
                                <div className="glossar_body">
                                  <p>
                                    Die Bewegung der Muskeln in unserem Darm,
                                    die sich in rhythmischen Wellen anspannen
                                    und entspannen und so den Darminhalt
                                    durchmischen und vorwärts schieben. Durch
                                    diese Bewegung werden im Magen auch
                                    Nahrungsmittel und Säuren gemischt und in
                                    eine dünne Flüssigkeit verwandelt.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-23 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Rehydrierung
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Der Prozess der Ersetzung von Wasser, das
                                    der Körper verloren hat, durch Trinken von
                                    Flüssigkeiten oder intravenöse Infusion.
                                    Eine Rehydrierung sollte mit der Zufuhr von
                                    Elektrolyten und Mineralien verbunden sein.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-24 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Reizdarm (Irritable Bowel Syndrome - IBS)
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Eine häufige Störung des Magen-/Darmtrakts,
                                    die durch Bauchschmerzen, einen
                                    aufgetriebenen Leib, Schleim im Stuhl und
                                    unregelmäßige Stuhlhäufigkeit und
                                    -beschaffenheit mit einem Wechsel von
                                    Durchfall und Verstopfung gekennzeichnet
                                    ist.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-25 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Schleim</div>
                                <div className="glossar_body">
                                  <p>
                                    Das zähflüssige Produkt der Schleimhäute,
                                    mit denen bestimmte Organe wie die Nase, der
                                    Mund, der Hals und die Vagina ausgekleidet
                                    sind.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-26 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Sigmoidoskopie
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Untersuchung des Enddarms und des
                                    unteren Dickdarms mittels eines dünnen
                                    beleuchteten Schlauchs namens Sigmoidoskop,
                                    bei der Gewebe- oder Zellproben für die
                                    weitere Untersuchung entnommen werden
                                    können.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-27 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Stuhl</div>
                                <div className="glossar_body">
                                  <p>
                                    Auch als 'Darminhalt' bezeichnete feste
                                    Abfallprodukte, die aufgrund der
                                    Darmbewegungen den Enddarm passieren. Der
                                    Stuhl besteht aus unverdauter Nahrung,
                                    Bakterien, Schleim und abgestorbenen Zellen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-28 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Symptom</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein physisches oder mentales Anzeichen, dass
                                    eine Person ein Leiden oder eine Krankheit
                                    hat. Gängige Symptome sind Durchfall,
                                    Kopfschmerzen, Fieber, Müdigkeit, Übelkeit,
                                    Schmerzen und Depressionen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-29 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Übelkeit</div>
                                <div className="glossar_body">
                                  <p>
                                    Brechreiz, der vielfältige Ursachen haben
                                    kann wie Krankheit, Medikamente und
                                    Störungen im Innenohr.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-30 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Verstopfung</div>
                                <div className="glossar_body">
                                  <p>
                                    Wenige und häufig unvollständige
                                    Darmentleerungen; das Gegenteil von
                                    Durchfall.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-31 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Virus</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Virus ist ein winziger Zellparasit.
                                    Viren sind nicht eigenständig lebensfähig.
                                    Sie dringen in Zellen ein, übernehmen die
                                    Kontrolle über ihre Reproduktionsmechanismen
                                    und greifen später auf andere Zellen über.
                                    Sie können 10.000 Mal kleiner sein als
                                    Bakterien.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-32 views-row-even views-row-last">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Zentrales Nervensystem
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Das zentrale Nervensystem (ZNS) ist der Teil
                                    des Nervensystems, das aus dem Gehirn und
                                    dem Rückenmark besteht.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf active-trail">
                    <a href="/glossar/" title className="active-trail active">
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
